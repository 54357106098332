.scrollBar {
	height: 50vh;
	border: 1px solid #ddd;
	overflow-y: scroll;
}

.FixedTitle{
    overflow: auto; 
    min-height: 87vh; 
    max-height: 87vh; 
    z-index: 0;
}
.FixedTitle2{
    overflow: auto; 
    min-height: 87vh; 
    max-height: 87vh;
    z-index: 0;
}

.PrincipalHead{
    position: sticky;
    min-height: 15vh;
    max-height: 15vh;
    top: 0; 
    z-index: 1;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.PrincipalHead2{
    position: sticky;
    min-height: 12vh;
    max-height: 12vh;
    top: 0; 
    z-index: 1;
    width: 100%;
    background-color: rgb(255, 255, 255);
}

.controlZIndex{
    z-index: 0;
}

.performance{
    width: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 1246px) {
    .performance{
        width: none;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .performance{
        width: none;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .performance{
        width: none;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .performance{
        width: none;
    }
}