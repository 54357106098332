.imgC{
    text-align: center;
    height: 150px;
    max-width: 100%;
}
.cartaHover:hover{
    background-color:rgb(232, 232, 232);
    transition: 0.2s;
    border-radius: .5rem;
}

.cartaHover{
    font-family:Helvetica;
    transition: 0.2s;
}

.item-card {
    align-items: center;
}