.cycle-inventory-card {
  border-radius: 10px;
}

.align-content {
  justify-items: center;
}

.search-input {
  background-color: #fff;
}
.radio-group {
  text-align: start;
}

@media screen and (max-width:613px) {
  .radio-group{
    padding-top: 1rem;
    text-align: center;
  }
}