
.my-custom-scrollbar {
    position: relative;
    height: 500px;
    overflow: auto;
  }
  .table-wrapper-scroll-y {
    display: block;
  }
  
  
  .tableFixHeadSmall          { overflow: auto; min-height: 10vh; max-height: 45vh; margin-bottom: 5%; z-index: 0; }
  .tableFixHeadSmall thead th { position: sticky; top: 0; z-index: 1; }
  
  
  table  { border-collapse: collapse; width: 100%; }
  th, td { padding: 8px 16px; }
  th     { background:#eee; }
  .fs-mini{
    font-size: 12px;
  }
  .row_hover:hover {
    background-color: rgb(151, 255, 119);
    transition: 0.1s;
  }
  .row_hover {
    transition: 0.1s;
  }
