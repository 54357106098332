@media screen and (max-width:614px) {
    .dissapearObject {
        display: none;
    }
}

@media screen and (min-width:614px) {
    .appearMovileObject {
        display: none;
    }

}

.especialButtonClose {
    background: none;
    border-color: none;
    border: none;
    font-size: 35px;
}


.especialButtonClose {
    margin-bottom: 10px;
}

.generalContainer {
    padding-top: 40px;
    margin-bottom: 1px;
}

.backLeyendSoftGreen {
    background: #6bb974c8;

}
.color-box{
    width: 20px;
    height: 20px;

    display: inline-block;
    margin-right: 10px;
  }

.backLeyendSoftYellow {
    background: #e6e183c8;

}

.backLeyendSoftRed {
    background: #cb7474c8;

}



.posIndex {
    z-index: 2;
}

.notcounted {
    background-color: rgba(45, 48, 48, 0.578);
}

.correctCount {
    background-color: rgba(12, 82, 2, 0.578);
}

.listDecoratorNone {
    list-style-type: none;
    display: flex;
    padding: 0;
}



.negativeCount {
    background-color: rgba(119, 30, 30, 0.578);
}

.positiveCount {
    background-color: rgba(2, 87, 93, 0.578);
}


.notcounted2 {
    background-color: rgba(45, 48, 48, 0.493);
    color: rgb(255, 255, 255);
}

.correctCount2 {
    background-color: rgba(11, 82, 2, 0.581);
    color: rgb(255, 255, 255);
}

.negativeCount2 {
    background-color: rgba(119, 30, 30, 0.626);
    color: rgb(255, 255, 255);
}

.positiveCount2 {
    background-color: rgba(2, 87, 93, 0.692);
    color: rgb(255, 255, 255);
}

.lobbyContainer {
    min-height: 52vh;
}

.textAreaGeneral textarea {
    resize: none;
}

.textAreaComment {
    width: 100%;
}


.detailIMG {
    width: 170px;
}

.justifyText {
    text-align: justify;
}

.pText p {
    font-size: 20px;
}

.iconColor {
    color: aliceblue;
    background-color: aliceblue;
}

.greenButton {
    background-color: rgb(0, 128, 55);
    color: aliceblue;
}

.greenButton:hover {
    background-color: rgb(34, 153, 84);
    color: aliceblue;
}

.blueButton {
    background-color: rgb(45, 68, 134);
    color: aliceblue;
}


.blueButton:hover {
    background-color: rgb(62, 91, 171);
    color: aliceblue;
}

.brownButton {
    background-color: rgb(120, 55, 20);
    color: aliceblue;
}

.brownButton:hover {
    background-color: rgb(150, 70, 30);
    color: aliceblue;
}

.orangeButton {
    background-color: rgb(230, 97, 37);
    color: aliceblue;
}

.orangeButton:hover {
    background-color: rgb(242, 120, 59);
    color: aliceblue;
}

.yellowButton {
    background-color: rgb(230, 185, 40)	;
    color: rgb(0, 0, 0);
}

.yellowButton:hover {
    background-color: rgb(255, 215, 60);
    color: rgb(0, 0, 0);
}

.redButton {
    background-color: rgb(180, 30, 30);
    color: aliceblue;
}

.redButton:hover {
    background-color: rgb(220, 50, 50);
    color: aliceblue;
}

.blackButton {
    background-color: rgb(39, 38, 38);
    color: aliceblue;
}

.blackButton:hover {
    background-color: rgb(0, 0, 0);
    color: aliceblue;
}

.aquaButton {
    background-color: rgb(20, 130, 120);
    color: aliceblue;
}

.aquaButton:hover {
    background-color: rgb(30, 170, 160);
    color: aliceblue;
}

.purpleButton {
    background-color: rgb(101, 45, 139);
    color: aliceblue;
}

.purpleButton:hover {
    background-color: rgb(123, 59, 167);
    color: aliceblue;
}

.yellowBackground {
    background-color: rgb(241, 195, 29);
    color: black;
}

.orangeBackground {
    background-color: rgb(230, 97, 37);
    color: aliceblue;
}

.greenBackground {
    background-color: rgb(0, 128, 55);
    color: aliceblue;
}

.blueBackground {
    background-color: rgb(45, 68, 134);
    color: aliceblue;
}


.brownBackground {
    background-color: rgb(120, 55, 20);
    color: aliceblue;
}

.redBackground {
    background-color: rgb(180, 30, 30);
    color: aliceblue;
}

.blackBackground {
    background-color: rgb(39, 38, 38);
    color: aliceblue;
}

.aquaBackground {
    background-color: rgb(20, 130, 120);
    color: aliceblue;
}

.purpleBackground {
    background-color: rgb(101, 45, 139);
    color: aliceblue;
}


.contPrincipal {
    min-height: 100vh;
    background-color: #f5f5f5;
}

.InputText p {
    font-size: 20px;
    word-wrap: break-word;
}

.InputTextBolder p {
    font-size: 20px;
    font-weight: bolder;
}

.InputTextBolderSimple {
    font-weight: bold;
}

.TitleText p {
    font-size: 30px;
    word-wrap: break-word;
}

.TitleText {
    font-size: 20px;
}


.grayBackground {
    background-color: rgb(198, 197, 197);
    color: rgb(0, 0, 0);
}

.textTableSize {
    word-wrap: break-word;
    font-size: 21px;
}

.checkBoxTitle {

    font-size: 31px;
    font-weight: bolder;
}

.textRadioButtonSize {
    font-size: 150%;
}

@media screen and (max-width:564px) {
    .ItemImg img {
        object-fit: contain;
    }
}

@media screen and (max-width:1060px)and (min-width:564px) {
    .ItemImg img {
        object-fit: contain;
    }
}

.ItemImg {
    width: 350px;
    height: 350px;


}

.ItemImg img {
    /*object-fit: contain;*/
    width: auto;
    height: 100%;

}

.AlignImgCenter {
    margin: auto;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkboxSize {
    scale: 1.5;
}


.backgroundGreen {
    background-color: rgb(17, 112, 21) !important;
}

.backgroundGray {
    background-color: rgb(197, 197, 197) !important;
}

.backgroundGrayLow {
    background-color: rgb(238, 238, 238) !important;
}

.backgroundOrange {
    background-color: rgb(243, 130, 10) !important;
}

.backgroundBlue {
    background-color: rgb(3, 14, 108) !important;
}

.backgroundWhite {
    background-color: rgb(255, 255, 255) !important;
}

.backgroundSky {
    background-color: rgb(255, 255, 255) !important;
}

.backgroundSky2 p {
    margin-bottom: 0;
}

.backgroundSky2,
.backgroundSky:hover,
.InputTextBolder2 p:hover {
    background-color: rgb(230, 230, 230) !important;
    border-radius: 1rem;
    padding-left: 1rem;
}

.InputTextBolder2 p {
    font-size: 18px;
    font-weight: bolder;
    border-radius: 1rem;
}

.InputTextBolder2 {
    margin-left: 1rem;
    margin-bottom: .2rem;
}

.nonTextDecoration {
    text-decoration: none;
}

.inputResize {
    width: 75px !important;
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .inputResize {
        width: 75px !important;

    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .inputResize {
        width: 75px !important;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1300px) {
    .inputResize {

        width: 75px !important;
    }
}

.colorHead {
    background-color: rgb(30, 38, 146);
    min-height: 15px;
}

.dashboardText {
    font-size: 28px;
}


.softGreen {
    background-color: rgba(3, 241, 90, 0.122) !important;
}

.softYellow {
    background-color: rgba(241, 221, 3, 0.122) !important;
}

.softRed {
    background-color: rgba(241, 3, 3, 0.122) !important;
}

.softGray {
    background-color: rgba(129, 129, 129, 0.122) !important;
}
.input-group-text {
    background: none;
}
.sort-icon {
    font-size: 1.2rem; /* Ajusta el tamaño de los íconos */
    margin: 0 5px; /* Espaciado horizontal entre los íconos */
    cursor: pointer; /* Cambia el cursor para indicar que son clicables */
    color: #fff; /* Asegúrate de que los íconos sean visibles en el fondo */
}

