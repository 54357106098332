.menuCompany .lateralMenu{
    background: #f4f4f4;
    font-size: 23px;
}
.menuCompany .lateralMenu h4{
    margin-left: 5%;
}
.menuCompany .lateralMenu ul{
    margin-left: 5%;
}
.menuCompany .top-menu{
    background: #f4f4f4;
    transition: background-color 0.3s ease;
}
.menuCompany .top-menu li a:hover{
    background-color: #8c8c8c;
    color: #fff
}
.menuCompany .navbar-brand img{
    width: 89px;
    margin-left: 5%;
}
.menuCompany .filling{
    height: 50px;
}
.menu-text {
    color: #71717a
}
.dropdown .menu-row:hover {
    color: #8c8c8c;
}

.menuCompany .icon {
    color: #1976d2;
}
.menuCompany .icon:hover {
    color: #1565c0;
}
.menuCompany .icon:visited {
    color: #1565c0;
}
.menuCompany .icon:visited:hover {
    color: #1976d2;
}
.navbar-nav .nav-item .menu-row {
    background: #f4f4f4;
    color: #8c8c8c;
}
.navbar-nav .nav-item .menu-row:hover {
    background: #8c8c8c;
    color: #f4f4f4;
}

@media screen and (max-width:613px) {
    .menuCompany .navbar-brand img{
        width: 55px;
        margin-left: 5%;
    }
}

@media screen and (max-width:1060px)and (min-width:589px) {
    .menuCompany .navbar-brand img{
        width: 57px;
        margin-left: 5%;
    }
}
