.ItemImage{
    width: auto;
    height: 350px;
}

.ItemImage img{
    width: auto;
    height: 100%;
}

.image-box {
    justify-items: center;
}

@media only screen and (max-width:576px) {
    .ItemImage  {
        margin-top: 30px;
        margin-bottom: 12px;
    }
}