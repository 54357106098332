#login{
    background-color:darkgrey;
    background-image: url('https://mayaland-src.s3.us-east-2.amazonaws.com/login3.jpg');
    background-size:cover;
    
    
    
}
.fixContainer{
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    z-index: 1;
}


.contLogin{
    background:rgb(255, 255, 255); 
    min-height: 50vh;
    margin-top: 10%;
    margin-bottom: 5%;
    border: 0.5px solid rgb(182, 182, 182);
    border-radius: 40px;
    z-index: 2;
    width: 55%;
}

.userImg{
    width: 200px;
    height: auto;
}

.userImg img{
    width: 100%;
}

.signin-btn {
    background-color: #1976d2;
    color: #fff;
    border-radius: 4px;
    padding: 6px 16px;
    box-shadow: 0 3px 5px rgba(0,0,0,0.2);
    transition: background-color 0.3s, box-shadow 0.3s;
    font-size: 1rem;
    width: 100%;
}
.signin-btn:hover {
    background-color: #1565c0;
    box-shadow: 0 4px 10px rgba(0,0,0,0.3);
    color: #fff;
}
.signin-btn:focus {
    background-color: #1565c0;
    box-shadow: 0 0 0 2px rgba(25,118,210,0.2);
}

.login-title {
    color: #333;
    line-height: 2.25rem;
    margin-bottom: 1rem;
}

.login-text {
    color: #666;
    line-height: 1.5rem;
    margin-bottom: 2rem;
}

.login-input {
    width: 100%;
    padding: 12px 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    background-color: transparent;
    transition: all 0.3s ease;
    color: #333;
    font-family: 'Roboto', sans-serif;
}

.login-input-label {
    top: 12px;
    left: 10px;
    font-size: 1.05rem;
    color: #9e9e9e;
    font-family: 'Roboto', sans-serif;
    transition: all 0.3s ease;
    pointer-events: none;
}
.main-content {
    flex-grow: 1;
}

@media (max-width:768px) {
    .fixContainer{
        padding: 2rem 1rem;
    }

    .contLogin {
        padding: 1.5rem;
        width: 100%;
        max-width: 350px;
    }
}