
/* Contenedor general */
.container {
    max-width: 1200px;
    margin: auto;
}

.company-title {
    color: #333;
    line-height: 2.25rem;
    margin-bottom: 1rem;
    font-size: 4rem;
    padding-top: 4rem;
    padding-bottom: 2rem;
}

.company-text {
    color: #666;
    line-height: 1.5rem;
    margin-bottom: 2rem;
    font-size: 2rem;
    padding-bottom: 4rem;
}

  /* Tarjetas */
.company-card {
    background-color: #000; /* Fondo negro */
    border: 1px solid #ccc; /* Borde personalizado */
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.company-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

  /* Cuerpo de la tarjeta */
.card-body {
    padding: 2rem 1rem;
}

  /* Logo de la compañía */
.company-logo img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
    min-height: 100px;
    object-fit: contain;
}

  /* Nombre de la compañía */
.company-name {
    font-size: 1.2rem;
    color: #fff;
    margin: 0;
    font-weight: bold;
    padding-top: 1rem;
}

.main-content {
  flex-grow: 1;
}

#companydash {
  min-height: 100vh;
}



  /* Responsivo */
@media (max-width: 768px) {
    .col-sm-3 {
    flex: 0 0 50%;
    max-width: 50%;
    }
}

@media (max-width: 576px) {
    .col-sm-3 {
    flex: 0 0 100%;
    /* max-width: 100%; */
    }
}
