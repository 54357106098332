.logoFooter img{
    width: 55px;
    margin-bottom: 1%;
}

.GenFooter{
    width: 100%;
    min-height: 175px;
    clear: both;


    
}

@media screen and (max-width:453px) {
    .logoFooter img{
        width: 50px;
        overflow: hidden;
    }
}