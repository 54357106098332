.lateralMenu {
    background:#0d47a1;
    font-size: 20px;
}

.lateralMenu h4,
.lateralMenu-altitude h4{
    margin-left: 5%;
}
.lateralMenu ul,
.lateralMenu-altitude ul{
    margin-left: 5%;
}

.dropDownMenu{
    background:#0d47a1;

}
.dropdown-menu.dropDownMenu.show {
    border: none;
}

.dropdown-menu li {
    margin: 0px 10px;
    padding: 3px 5px;
}

.dropdown-menu li a {
    border-radius: 15px;
}

.navbar-nav .nav-item.dropdown,
.navbar-nav .nav-item-altitude.dropdown {
    margin: 4px 0px;
}

.navbar-nav .nav-item .nav-link,
.navbar-nav .nav-item-altitude .nav-link {
    border-radius: 15px;
    padding: 5px;
}

.dropDownMenu li a:hover{
    background:#1976d2;
}

.navbar-nav .nav-item .nav-link.show,
.navbar-nav .nav-item .nav-link.active,
.navbar-nav .nav-item .nav-link:hover {
    background:#1976d2;
    color: #fff;
}

/* Altitude begins */

.lateralMenu-altitude {
    font-size: 20px;
}

.navbar-nav .nav-item-altitude .nav-link.show,
.navbar-nav .nav-item-altitude .nav-link.active,
.navbar-nav .nav-item-altitude .nav-link:hover {
    background:#757575;
    color: #fff;
}



.lateralMenu-altitude,
.dropDownMenu-altitude {
    background: #212121;
}

.dropDownMenu-altitude li a:hover{
    background:#757575;
}

/* Altitude finish */
.navbar-brand img{
    width: 180px;
    margin-left: 5%;
}

@media only screen and (min-width: 300px) {
    .navbar-brand img{
        width: 90.1px;
        margin-left: 5%;
    }
}

@media only screen and (min-width: 375px) {
    .navbar-brand img{
        width: 100.1px;
        margin-left: 5%;
    }
}
@media only screen and (min-width: 414px) {
    .navbar-brand img{
        width: 120.1px;
        margin-left: 5%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .navbar-brand img{
        width: 189.1px;
        margin-left: 5%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .navbar-brand img{
        width: 189.1px;
        margin-left: 5%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1300px) {
    .navbar-brand img{
        width: 189.1px;
        margin-left: 5%;
    }
}

.filling{
    height: 35px;
    
}