
.my-custom-scrollbar {
  position: relative;
  height: 500px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}


.tableFixHead          { overflow: auto; min-height: 10vh; max-height: 70vh; margin-bottom: 5%; z-index: 0; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }


.hightSizeTable { min-width:600vh;}

table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#eee; }
.fs-mini{
  font-size: 12px;
}
.row_hover:hover {
  background-color: rgb(151, 255, 119);
  transition: 0.1s;
}
.row_hover {
  transition: 0.1s;
}




.sticky-col
{
  
  position:sticky;
  left:0px;
  background-color:rgb(255, 255, 255);
  z-index: 4!important;
}
.sticky-col-head
{
  position:sticky;
  left:0px;
  background-color:rgb(0, 0, 0)!important;
  z-index: 5!important;
}

.tableAnidada{
  margin-top: 35px;
}


.positionStartText{
  text-align: left;
}
.positionEndText{
  text-align: right;
}

.colfix{
  width: 1000px!important;
}

 